"use client";

import React, { Suspense } from "react";

import PageNotFound from "@/components/pages/page-not-found/page-not-found";
import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";
import { metaConfig } from "@/utils/meta-config";

import "@/app/globals.css";

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");
const { title, favicon } = metaConfig[brand] || metaConfig.staysure;

export const metadata = {
  title,
  icons: {
    icon: favicon,
  },
};

function NotFound() {
  return (
    <Suspense fallback={<div />}>
      <div data-testid="notfound-page-testid">
        <PageNotFound />
      </div>
    </Suspense>
  );
}

export default NotFound;
