import {
  MAIN_PAGE_URL,
  AVT_MAIN_PAGE_URL,
  EXPAT_MAIN_PAGE_URL,
} from "@/constants";

const logoClickURLs = [
  {
    brand: "staysure",
    logoUrl: MAIN_PAGE_URL,
  },
  {
    brand: "avanti",
    logoUrl: AVT_MAIN_PAGE_URL,
  },
  {
    brand: "expat",
    logoUrl: EXPAT_MAIN_PAGE_URL,
  },
];

export default logoClickURLs;
