"use client";

import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useSelector } from "react-redux";

import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";
import { RootState } from "@/store/root-reducer";
import isBCUKEnabledByBrand from "@/utils/bcuk-enabled.util";

import logos from "./logo-config";
import Classes from "./logo.module.css";
import logoClickUrls from "./url-config";
import Styles from "../../../app/home-page.module.css";

interface LogoProps {
  color: string;
  heightClass?: string;
  size?: string;
  className?: string;
  showondesktop?: string;
  brand?: "staysure" | "avanti" | "expat";
}

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

function Logo({
  color,
  heightClass = "",
  size = "m",
  className = "",
  showondesktop,
}: Readonly<LogoProps>): JSX.Element {
  const searchParams = useSearchParams();
  const partnership = searchParams.get("partnership");
  const question = useSelector((state: RootState) => state.question);
  const quote = useSelector((state: RootState) => state.quote);
  const { quoteResponse } = question;
  const partnerCode =
    quote.attributes?.partnerCode ?? quoteResponse?.attributes?.partnerCode;
  function getLogoImage(lBrand: string, lColor: string, lSize: string): string {
    const updateSize =
      brand === "avanti" && showondesktop === "false" ? "m" : lSize;

    let logoImage = "";
    logos.forEach((logo) => {
      const conditions =
        logo.brand === lBrand &&
        logo.color === lColor &&
        logo.size === updateSize;

      if (
        isBCUKEnabledByBrand &&
        (partnership === "bcuk" || partnerCode === "BCUK")
      ) {
        if (conditions && logo.bcuk) {
          logoImage = logo.logoImage;
        }
      } else if (conditions && !logo.bcuk) {
        logoImage = logo.logoImage;
      }
    });
    return logoImage;
  }

  function getLogoClickUrl(lBrand: string): string {
    let logoClickUrl = "";
    logoClickUrls.forEach((logoUrl) => {
      if (logoUrl.brand === lBrand) {
        logoClickUrl = logoUrl.logoUrl;
      }
    });
    return logoClickUrl;
  }

  return (
    <div
      className={` ${Styles.logo_wrapper} logo-wrapper md:pl-[35px] pl-spacing-m lg:pt-[20px] md:pt-[16px] pt-[12px] pb-[14px] md:h-[80px] sm:h-[64px] ${className} ${heightClass}`}
      data-testid="logo-wrapper-test-id"
    >
      {/* ${showondesktop === "true" ? "h-[80px]" : "h-[64px]"}  this is not working properly so, we moved to media query for the logo height */}
      <Link
        id="logo-automate-test-id"
        href={getLogoClickUrl(brand)}
        role="button"
        tabIndex={0}
        className={`inline-block ${Classes.logo}`}
        target="_blank"
        data-testid="logoLink-test-id"
      >
        <Image
          src={getLogoImage(brand, color, size)}
          alt={`${brand}-logo`}
          className="md:max-w-[158px] sm:max-w-[115px] max-w-[80px] w-full"
          width={127}
          data-testid="logo-image-test-id"
        />
      </Link>
    </div>
  );
}

export default Logo;
