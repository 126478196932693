"use client";

/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from "next/dynamic";
import React, { useEffect, useMemo, useRef } from "react";
import { HelpButton } from "staysure-component-library";

import withBrand from "@/components/with-brand";
import { SECONDARY_PRODUCT, PRODUCT } from "@/constants";
import useWindowSize from "@/hooks/useWindowSize/useWindowSize";
import FocusLock from "@/utils/FocusedLock";

import Styles from "./help-overlay-button.module.css";

const HelpOverLay = dynamic(() => import("../help-overLay/help-overlay"), {
  ssr: false,
});

function HelpOverLayButton(
  props: Readonly<{
    brand?: "staysure" | "avanti";
  }>
) {
  const { brand } = props;

  const productBrand: "staysure" | "avanti" | "expat" =
    SECONDARY_PRODUCT === "expat"
      ? "expat"
      : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

  const avatarImage = {
    staysure: "/images/susie-profile.svg",
    avanti: "/images/susie-profile.svg",
    expat: "/images/get-help-support-icon.svg",
  };

  const ref = useRef<HTMLButtonElement>(null);
  const windowSize = useWindowSize();

  const [isOpen, setIsOpen] = React.useState(false);

  const { top, right } = useMemo(() => {
    const buttonRect = ref?.current?.getBoundingClientRect();
    const topVal = buttonRect?.bottom ?? 0;
    const rightVal = buttonRect?.right ?? 0;
    return { top: topVal, right: rightVal };
  }, [ref?.current?.getBoundingClientRect(), windowSize]);

  // const stringWithoutSlashes =
  //   typeof window !== "undefined" &&
  //   window.location.pathname.replace(/\//g, "");

  // useGtmPopup({
  //   tripType: stringWithoutSlashes || LandingPage,
  //   isPopupOpen: isOpen,
  //   popupName: "get help - live chat with agent",
  // });

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // useEffect(() => {
  //   if (!isOpen && ref.current) {
  //     ref.current.focus();
  //   }
  // }, [isOpen]);

  return (
    <div
      data-testid="help-overlay-button-wrapper-test-id"
      className="w-[165px] h-[58px] bg-surface-neutral-option-2 absolute top-[-1px] right-[-1px] text-text-functional-subtle rounded-bl-[28px]"
    >
      <FocusLock disabled={!isOpen}>
        {isOpen && (
          <HelpOverLay
            top={top}
            right={right}
            isOpen={isOpen}
            setIsOpen={(val) => {
              setIsOpen(val);
            }}
          />
        )}
        <div
          className={`relative z-[101] cursor-pointer mt-[10px] ml-[8px] hover:outline outline-offset-2 outline-interactive-focus-default outline-2 rounded-full ${Styles.get_help}`}
        >
          <HelpButton
            id="helpoverlay-automate-test-id"
            tab-index={2}
            isOpen={isOpen}
            buttonRef={ref}
            onClick={() => {
              setIsOpen((val) => !val);
            }}
            avatarSrc={avatarImage[productBrand]}
            brand={brand}
          />
        </div>
      </FocusLock>
      <div className={` ${Styles.curved_corner_top_right} absolute `} />
      <div className={` ${Styles.curved_corner_bottom_right} absolute `} />
    </div>
  );
}

export default withBrand(HelpOverLayButton);
