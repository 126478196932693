import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";

import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import HelpOverlayButton from "@/components/molecules/help-overlay-button/help-overlay-button";
import Logo from "@/components/molecules/logo-component/logo";
import { SessionSuccessPage } from "@/config/app-constant";
import fetchNotFoundContent from "@/hooks/magnolia/fetchNotFound";
import { NotFoundProps } from "@/types/LandingPageType";
import { storageAvailable } from "@/utils/common.utils";

import Styles from "./page-not-found.module.css";

function PageNotFound() {
  const router = useRouter();
  const [notFoundContent, setNotFoundContent] = useState<NotFoundProps | null>(
    null
  );
  const [successPage, setSuccessPage] = useState<string | null>(null);
  useEffect(() => {
    Promise.resolve(fetchNotFoundContent())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setNotFoundContent(res && res ? res : null);
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && window && storageAvailable()) {
      const url = sessionStorage.getItem(SessionSuccessPage);
      setSuccessPage(url);
    }
  }, []);
  const renderHelpButtonOverlay = () => {
    return <HelpOverlayButton />;
  };
  const renderLogo = () => {
    return <Logo color="white" heightClass="h-[80px]" size="xl" />;
  };
  return (
    <div className="bg-surface-neutral-option-2 page-not-found">
      <div className="max-w-[1200px] mx-auto my-0 w-[100%] relative min-h-[calc(100vh-12px)] md:pb-0 h-full ">
        <main className=" lg:py-spacing-xxl md:py-spacing-xl py-spacing-l lg:min-h-[calc(100vh-96px)] md:min-h-[calc(100vh-64px)] min-h-[calc(100vh-48px)] h-full">
          <div
            className={` grid md:grid-cols-12 grid-cols-6 gap-spacing-l xl:mx-auto lg:mx-[72px] md:mx-[48px] 
            mx-[24px] relative min-h-[inherit] h-full`}
          >
            <div className={` col-span-12 h-full`}>
              <div
                className={`  bg-dark-surface-accent-1-primary max-h-full text-surface-neutral-option-2 
                rounded-radius-xxl relative h-full flex flex-col lg:h-[713px] min-h-[400px]`}
              >
                {renderHelpButtonOverlay()}
                <div className={` ${Styles.logo_container}`}>
                  {renderLogo()}
                </div>

                <div className=" tablet:px-spacing-5xl px-spacing-l flex flex-col items-center h-full justify-center">
                  <div className=" md:max-w-[512px] w-full mx-auto flex flex-col items-center">
                    <h1
                      className=" md:max-w-none max-w-[264px] w-full font-header-font md:text-[49px] md:leading-[56.35px] 
                    text-[32px] leading-[36.8px] md:mb-spacing-xl mb-spacing-l text-center "
                    >
                      {notFoundContent?.descriptionText ||
                        "Sorry, we can’t find the page you’re looking for"}
                    </h1>
                    <LabelButtonWrapper
                      id="page-not-found-cta-button"
                      label={notFoundContent?.ctaButtonText || "Go back"}
                      hierarchy="primary"
                      type="button"
                      className=" md:max-w-[342px] w-full leading-[18px]"
                      isDisabled={!successPage}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        successPage && router.push(successPage);
                        if (window && storageAvailable()) {
                          sessionStorage.removeItem(SessionSuccessPage);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
