import AvantiLogoPrimaryXL from "../../../public/images/logos/avanti-logo-primary-xl.svg";
import AvantiLogPrimary from "../../../public/images/logos/avanti-logo-primary.svg";
import AvantiLogoWhiteXL from "../../../public/images/logos/avanti-logo-white-xl.svg";
import AvantiLogoWhite from "../../../public/images/logos/avanti-logo-white.svg";
import ExpatLogoPrimary from "../../../public/images/logos/expat-logo-primary.svg";
import ExpatLogoWhite from "../../../public/images/logos/expat-logo-white.svg";
import StaysureBCUKLogoPrimary from "../../../public/images/logos/staysure-bcuk-primary.svg";
import StaysureBCUKLogoWhite from "../../../public/images/logos/staysure-bcuk-white.svg";
import StaysureLogoPrimaryXL from "../../../public/images/logos/staysure-logo-primary-xl.svg";
import StaysureLogoPrimary from "../../../public/images/logos/staysure-logo-primary.svg";
import StaysureLogoWhite from "../../../public/images/logos/staysure-logo-white.svg";

const logos = [
  {
    brand: "staysure",
    color: "primary",
    size: "xl",
    logoImage: StaysureLogoPrimaryXL,
  },
  {
    brand: "staysure",
    color: "primary",
    size: "m",
    logoImage: StaysureLogoPrimary,
  },
  {
    brand: "staysure",
    color: "white",
    size: "xl",
    logoImage: StaysureLogoWhite,
  },
  {
    brand: "staysure",
    color: "primary",
    size: "xl",
    bcuk: true,
    logoImage: StaysureBCUKLogoPrimary,
  },
  {
    brand: "staysure",
    color: "white",
    size: "xl",
    bcuk: true,
    logoImage: StaysureBCUKLogoWhite,
  },
  {
    brand: "avanti",
    color: "primary",
    size: "xl",
    logoImage: AvantiLogoPrimaryXL,
  },
  {
    brand: "avanti",
    color: "primary",
    size: "m",
    logoImage: AvantiLogPrimary,
  },
  {
    brand: "avanti",
    color: "white",
    size: "m",
    logoImage: AvantiLogoWhite,
  },
  {
    brand: "avanti",
    color: "white",
    size: "xl",
    logoImage: AvantiLogoWhiteXL,
  },
  {
    brand: "expat",
    color: "white",
    size: "xl",
    logoImage: ExpatLogoWhite,
  },
  {
    brand: "expat",
    color: "primary",
    size: "xl",
    logoImage: ExpatLogoPrimary,
  },
];

export default logos;
