export const metaConfig = {
  staysure: {
    title: "Staysure™ Travel Insurance - It's Worth Doing Right",
    description:
      "Staysure is the over 50s expert, offering a wide range of products and services including travel insurance, private medical, life insurance, and more.",
    favicon: "/favicon.ico",
  },
  avanti: {
    title: "Avanti™ Travel Insurance – Get the best cover for your holiday",
    description:
      "Want Single, Annual Or Cruise Travel Insurance? Medical Conditions Covered. Unlimited Medical Expenses Option. Get A Quote Today!",
    favicon: "/avanti.ico",
  },
  expat: {
    title:
      "Staysure Expat™ Travel Insurance – Holiday Insurance for UK Expats",
    description:
      "EXPAT is the over 50s expert, offering a wide range of products and services including travel insurance, private medical, life insurance and more.",
    favicon: "/favicon.ico",
  },
};
